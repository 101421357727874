.project-grid-item {
  padding: 0.5em;
  align-items: center; /* Center items vertically within the grid item */
  border: 3px grey solid;
  border-radius: 25px;
  max-width: 100%;
  overflow: hidden;
  filter: grayscale(100%);
}

.project-grid-item:hover {
  filter: none;
  border-color: aqua;
  cursor: pointer;
}
