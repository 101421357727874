Experience * {
  box-sizing: border-box;
}

.experience table {
  border-collapse: separate;
  margin-left: auto;
  margin-right: auto;
}

.job-name {
  text-transform: capitalize;
  margin-left: 1em;
}

.job-date-col {
  vertical-align: top;
  width: 30%;
  display: none;
  margin-right: 2em;
  padding-top: 0.5em;
}

.job-date-large {
  font-weight: lighter;
}

.job-date-mobile {
  margin-left: 1.3em;
  font-weight: lighter;
}

.job-desc-list {
  list-style: none;
  font-size: 1.2em;
}

li * {
  display: inline;
}

li > p {
  line-height: 1.75em;
}

.experience ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #fefefe; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1.3em; /* Also needed for space (tweak if needed) */
}

@media (min-width: 769px) {
  .job-date-mobile {
    display: none;
  }
  .job-date-col {
    width: 100%;
    display: block;
  }
}
