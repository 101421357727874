nav {
  overflow: hidden;
  padding-right: 5%;
}

nav > ul {
  list-style-type: none;
  float: right;
}

.nav-item {
  display: inline-block;
  padding: 0rem 5rem;
  font-size: medium;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 10px;

  scale: 1;

  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s linear; /* opera */
  -ms-transition: all 0.1s linear; /* IE 10 */
  -moz-transition: all 0.1s linear; /* Firefox */
}

@media only screen and (max-width: 800px) {
  .nav-item {
    padding: 0rem 1rem;
  }
}

.nav-item > a {
  color: #fefefe;
  text-decoration: none;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 2em;
  margin: -2em;
}

.nav-item:hover {
  cursor: pointer;
  transform: scale(1.1);

  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s linear; /* opera */
  -ms-transition: all 0.1s linear; /* IE 10 */
  -moz-transition: all 0.1s linear; /* Firefox */
}
