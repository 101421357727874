/* Hero */
:root {
  --hero-height: 100vh;
}

.background-container {
  background-color: rgb(86, 86, 86);
  height: 98vh;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.hero-container::before {
  content: "";
  background-image: url("https://abarrett-io.nyc3.cdn.digitaloceanspaces.com/hero/Andrew%20Barrett%20Color%201080p.jpg");
  /* background-size: cover; */
  background-position: center center;
  /* max-width: 2560px; */
  background-repeat: no-repeat;
  background-size: 100% auto;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* margin: 0 auto; */

  z-index: 0; /* Places the background behind the container content */
  opacity: 0; /* Start with an invisible background */
  animation: fadeIn 1.5s forwards; /* 3s is the duration; adjust as needed */
}

@media only screen and (max-width: 2100px) {
  .hero-container::before {
    background-size: auto 100%;
  }
}

.hero-container {
  width: 100vw; /* Use appropriate dimensions*/
  height: var(--hero-height);
  /* Use appropriate dimensions */
  /* max-width: 2560px; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-content {
  z-index: 1;
  margin-left: 10%;
}

.signature-container {
  height: 15vh;
  width: 50%;
  margin-bottom: 4vh;
}

@media only screen and (max-width: 1000px) {
  .signature-container {
    width: 70%;
    max-height: 100px;
  }
  .hello-title {
    font-size: medium;
  }
}

.site-nav {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.hero-button {
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 1.5em 2em;
  font-size: small;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
}

.hero-button:link {
  text-decoration: none;
}

.hero-button:visited {
  text-decoration: none;
}

.hero-button:hover {
  text-decoration: none;
}

.hero-button:active {
  text-decoration: none;
}

.mask {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}

.andrew-barrett .mask {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
}

.andrew-barrett {
  height: 100%;
  width: 100%;
  /* max-width: 1000px; */
}

/* Animation the stroke-dashoffset to a zero length */
@keyframes strokeOffset {
  to {
    stroke-dashoffset: 0;
  }
}

:root {
  --letter-duration: 1s;
  --delay-letters: 1s;
  --delay-clickables: 3.2s;
}

/* Apply the animation to each mask */
#mask-A {
  animation: strokeOffset var(--letter-duration) linear forwards
    var(--delay-letters);
}

#mask-n1 {
  animation: strokeOffset var(--letter-duration) linear forwards
    calc(var(--delay-letters) + 0.25s);
}

#mask-n2 {
  animation: strokeOffset var(--letter-duration) linear forwards
    calc(var(--delay-letters) +0.3s);
}

#mask-d {
  animation: strokeOffset var(--letter-duration) linear forwards
    calc(var(--delay-letters) + 0.4s);
}

#mask-r {
  animation: strokeOffset var(--letter-duration) linear forwards
    calc(var(--delay-letters) + 0.6s);
}

#mask-e1 {
  animation: strokeOffset var(--letter-duration) linear forwards
    calc(var(--delay-letters) + 0.7s);
}
#mask-w {
  animation: strokeOffset var(--letter-duration) linear forwards
    calc(var(--delay-letters) + 0.8s);
}
#mask-b {
  animation: strokeOffset var(--letter-duration) linear forwards
    calc(var(--delay-letters) + 1s);
}
#mask-a-2 {
  animation: strokeOffset var(--letter-duration) linear forwards
    calc(var(--delay-letters) + 1.4s);
}

#mask-barrett-r-1 {
  animation: strokeOffset var(--letter-duration) linear forwards
    calc(var(--delay-letters) + 1.6s);
}
#mask-barrett-r-2 {
  animation: strokeOffset var(--letter-duration) linear forwards
    calc(var(--delay-letters) + 1.75s);
}
#mask-e-2 {
  animation: strokeOffset var(--letter-duration) linear forwards
    calc(var(--delay-letters) + 1.9s);
}
#mask-barrett-t {
  animation: strokeOffset var(--letter-duration) linear forwards
    calc(var(--delay-letters) + 2s);
}
#mask-barrett-t-line-v {
  animation: strokeOffset var(--letter-duration) linear forwards
    calc(var(--delay-letters) + 2.2s);
}

@keyframes yTranslateAnimate {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.hello-title {
  letter-spacing: 0.05rem;
  font-weight: 400;
  margin-bottom: 2rem;
}

.descend {
  transform: translateY(-25px);
  animation: yTranslateAnimate 1s forwards var(--delay-clickables);
  opacity: 0;
}

.ascend {
  transform: translateY(25px);
  animation: yTranslateAnimate 1s forwards var(--delay-clickables);
  opacity: 0;
}

section {
  padding: 0;
}
